/*This avoids error overlay, as we have ErrorBoundary*/
iframe {
  display: none;
}

html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}