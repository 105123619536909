.error-page {
  text-align: center;
  margin-top: 50px;
}

.error-heading {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

.error-message {
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
}