
.sitefooter {
  background-color: #282c34;
  color: white;
  text-align: center;
  width: 100%;
  padding: 1rem;
}

.sitefooter-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sitefooter-nav {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.sitefooter-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.sitefooter-nav li {
  margin: 5px 0;
}

.sitefooter-nav a {
  color: white;
  text-decoration: none;
}

.sitefooter-nav a:hover {
  text-decoration: underline;
}